import React from 'react';
import Layout from '../components/Layout';
import PrivacyTemplate from '../templates/PrivacyTemplate';

const PrivacyPage = () => {
	return (
		<Layout>
			<PrivacyTemplate />
		</Layout>
	);
};

export default PrivacyPage;
