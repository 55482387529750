import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

//import Container from 'react-bulma-components/lib/components/container';
import { PageWrap } from '../styles/common';

export default () => {
	return (
		<PageWrap>
			<Helmet>
				<title>Privacy Policy | Spergel Corporate</title>
			</Helmet>
			<Content>
				<div className="container" style={{ padding: '0 2rem' }}>
					<h1>Privacy Policy</h1>
					<h2>Spergel Customer Privacy Statement</h2>
					<p>
						At SPERGEL, we are required to collect a certain amount of personal
						information to allow us to help you through your financial
						difficulties. We take the security and privacy of the personal
						information we collect very seriously. This privacy policy outlines
						our policies and procedures regarding your personal information.
					</p>
					<h3>1. Why we collect personal information</h3>
					<p>
						We collect personal information to be used to fulfill our duties to
						you under whichever service we are providing to you. We only collect
						that information that is necessary to serve you as our client.
					</p>
					<h3>2. Sources of personal information</h3>
					<p>
						We receive personal information directly from you and/or from third
						parties when it is needed to perform the service you have engaged us
						to perform.
					</p>
					<h3>3. Release of personal information</h3>
					<p>
						The only instances by which we release your personal information are
						to provide the services to you that you have engaged and consented
						for us to do on your behalf. We will limit the release of personal
						information to the non-related organization to that which is
						absolutely necessary in order to render their service to us.
					</p>
					<h3>4. How long we hold your personal information</h3>
					<p>
						We retain your personal information for as long as is legally
						required by federal or provincial legislation for the services
						provided to{' '}
					</p>
					you, after which time we destroy all documents.
					<h3>5. Security Measures</h3>
					<p>
						We restrict the access to your information to those employees
						directly administering your file. We employ physical and
						technological security measures, as well as security-related
						administrative policies to appropriately protect your personal
						information as effectively as possible. We also train and educate
						our employees on all data-related security measures and protocols.
						In the event there is a material breach of privacy safeguards, we
						will inform the Privacy Commissioner of Canada and all appropriate
						parties in a timely manner.
					</p>
					<h3>6. Access to your personal information</h3>
					<p>
						You may access your personal information at any time. Please contact
						us at 416-497-1660 anytime.
					</p>
					<h3>7. Contact Us</h3>
					<p>
						If you wish to submit comments about our Customer Privacy Statement,
						please contact us at:
					</p>
					<p>
						SPERGEL 505 Consumers Road, Suite 200 North York, Ontario M2J 4V8
						Attn: Privacy Officer 416-497-1660 www.spergel.ca
					</p>
				</div>
			</Content>
		</PageWrap>
	);
};

const Content = styled.div``;
